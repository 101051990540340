import gql from 'graphql-tag'
import { appClient } from '@/plugins/vue-apollo.plugin'
import { ulog } from '@/common/utils/AppConsole'
import { AssemblyCalendar } from '../schema/app.graphql.types'
import { AssemblyCalendarParams } from '@/models/customview/AssemblyCalendar'

export namespace GQLAssemblyCalendar {
  export namespace Get {

    const query = gql`
      query GetAssemblyCalendar($from: String!, $to: String!, $branchOfficeId: Int, $workerGroupId: Int) {
        getAssemblyCalendar(from: $from, to: $to, branchOfficeId: $branchOfficeId, workerGroupId: $workerGroupId) {
          from
          to
          totalCount
          edges {
            workerGroup {
              id,
              key,
              fullpath,
              workerGroupID,
              color,
              availableMorning,
              availableAfternoon,
              availableEvening
            }

            assemblies {
              id,
              key,
              fullpath,
              isPriority,
              orderNumber,
              state,
              firstName,
              lastName,
              city,
              installationDate,
              installationMorning,
              installationAfternoon,
              installationEvening
              parameters,
              selectedDoorLabel,
              selectedDoorLabelExtended,
              invoicingData {
                invoicedEntity, directSell, firstName, lastName, verificationType,
                birthdate, companyName, ico, dic, street, streetNumber, city,
                postcode, vatRate, liability, paymentMethod
              }
            }
          }
        }
      }
    `

    export type Variables = AssemblyCalendarParams

    export type Data = {
      getAssemblyCalendar: AssemblyCalendar
    }

    export const execute = (variables: Variables) => appClient
      .query<Data, Variables>({ query, variables, fetchPolicy: 'no-cache' })
      .then(r => r.data.getAssemblyCalendar.edges != null
        ? r.data.getAssemblyCalendar.edges
        : ulog('e', 'GQLAssemblyCalendar(R) failed', r)
      )
      .catch(e => ulog('e', 'GQLAssemblyCalendar(R) exception', e))

  }
}
