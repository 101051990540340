import { GQLConfigActions } from '@/api/graphql/app/GQLConfigActions'
import { GQLConfiguration } from '@/api/graphql/app/GQLConfiguration'
import { GQLProtocolData } from '@/api/graphql/app/orderconfig/GQLProtocolData'
import { ConfigAPI } from '@/api/interfaces/database/config.api.interface'
import { generateInstallationDate } from '@/common/logic/order/assembly'
import { PimcoreConfigAction } from '@/models/order/workflow/Actions'
import { WorkerGroup } from '@/models/workers/WorkerGroup'
import { Configuration } from '@/models/order/Configuration'


export const IConfigAPI: ConfigAPI = {

  get: GQLConfiguration.Read.procedure,
  update: GQLConfiguration.Update.procedure,

  protocolCreate: GQLProtocolData.Create.procedure,
  protocolUpdate: GQLProtocolData.Update.procedure,

  assignTermData: async (isDetail, config, date, am, pm, evening, wg, s) => {
    const c = { ...config }
    c.installationDate = generateInstallationDate(date, am, pm)
    c.installationMorning = am
    c.installationAfternoon = pm
    c.installationEvening = evening
    c.workerGroup = wg

    const data = {
      fullpath: c.fullpath,
      installationDate: generateInstallationDate(date, am, pm),
      installationMorning: am,
      installationAfternoon: pm,
      installationEvening: evening,
      workerGroup: {
        id: wg.id,
      },
    }

    // overime, ci nastavujeme termin montaze z detailu alebo upravy obj.
    if (isDetail) {
      const gqlUpdate = await GQLConfiguration.Update.procedure(data as Configuration)
      if (gqlUpdate) {
        c.installationState = await IConfigAPI.assignTermAction(c, s)
        return c
      }
      else {
        return undefined
      }
    }
    else {
      // ak nastavujeme montaz z EditOrder, nieje nutne posielat ziadne GQL, to sa vykona pri ulozeni objednavky
      return c
    }
  },

  clearAssembly: async (isEdit, config) => {
    const c = { ...config }

    c.installationDate = ''
    c.installationMorning = false
    c.installationAfternoon = false
    c.workerGroup = WorkerGroup()

    if (isEdit) {
      c.installationState = 'waitingForTerm'
    }
    else {
      const result = await GQLConfigActions.Apply.procedure(c, 'cancelTerm')
      if (!GQLConfigActions.isError(result)) {
        c.installationState = result
      }
    }
    return c
  },

  assignTermAction: async (config, state) => {
    const action: PimcoreConfigAction = state === 'orderApproved' ? 'reassignTerm' : 'assignTerm'
    const result = await GQLConfigActions.Apply.procedure(config, action)
    return GQLConfigActions.isError(result)
      ? config.installationState
      : result
  },

}
